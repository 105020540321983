import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import {
   Button,
   ImageCard,
   ImageMatchButton,
   ImageMatchModal,
   ImageViewer,
   Modal,
   Svg,
   UploadImageButton,
} from '../../common'

import * as styles from './images.module.scss'
import { EditImageModalProps, SaveImageRecordParams, CardImage } from './types'
import { Col, Row } from 'react-grid-system'
import {
   CatalogueImage,
   ImageMatchPayload,
} from '../../../hooks/useImageMatch/types'
import useImageMatch from '../../../hooks/useImageMatch/useImageMatch'
import { Dropdown, Input } from '../../common/form/fields'
import { useManufacturers } from '../../../hooks'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import { modalStyles } from './constants'

const EditImageModal = ({
   isOpen,
   handleClose,
   imageRecord,
   handleSave,
}: EditImageModalProps) => {
   const [localImages, setLocalImages] = useState<CardImage[]>([])
   const [openImageViewer, setOpenImageViewer] = useState<boolean>(false)
   const [imageIdsToDelete, setImageIdsToDelete] = useState<number[]>([])
   const defaultManufacturer = useMemo(
      () => ({
         value: String(imageRecord.ManufacturerID),
         label: imageRecord.Manufacturer,
      }),
      [imageRecord]
   )
   const defaultModelNumber = useMemo(
      () => String(imageRecord.ModelNumber),
      [imageRecord]
   )
   const [selectedManufacturer, setSelectedManufacturer] =
      useState<Option>(defaultManufacturer)
   const [modelNumber, setModelNumber] = useState<string>(defaultModelNumber)

   useEffect(() => {
      if (imageRecord?.CatalogueImages?.length > 0) {
         const sortedImages = imageRecord.CatalogueImages.sort(
            (img1, img2) => img1?.Position - img2?.Position
         )
         setLocalImages(sortedImages)
      }
   }, [imageRecord?.CatalogueImages])

   const onDeleteImageClick = (image: CatalogueImage) => {
      const { CatalogueImageId, PictureFullURL } = image
      if (
         CatalogueImageId !== 0 &&
         !imageIdsToDelete.includes(CatalogueImageId)
      ) {
         setImageIdsToDelete((prevState) => [...prevState, CatalogueImageId])
      }
      const updatedImages = localImages
         .filter((img) => img?.PictureFullURL !== PictureFullURL)
         .map((image, index) => ({
            ...image,
            Position: index,
         }))
      setLocalImages(updatedImages)
   }

   const onImageUpload = (
      file: File,
      newFile: File,
      mimeType: string,
      imageUrl: string
   ) => {
      const isDuplicateImage = !!localImages.find(
         (img) => img.PictureFullURL === imageUrl
      )
      if (!isDuplicateImage) {
         const newImage: CardImage = {
            CatalogueImageId: 0,
            IsMatchedImage: false,
            IsCatalogueImage: false,
            Position: localImages.length,
            MimeType: mimeType,
            PictureFullURL: imageUrl,
            PictureSlug: '',
            ThumbnailFullURL: imageUrl,
            ThumbnailSlug: '',
            File0: file,
            File1: newFile,
         }
         setLocalImages((prevState) => [...prevState, newImage])
      }
   }

   const title = useMemo(
      () =>
         `Edit Images for ${imageRecord.Manufacturer} - ${imageRecord.ModelNumber}`,
      [imageRecord]
   )

   const allowViewGallery = useMemo(
      () => localImages?.length > 0,
      [localImages]
   )

   const viewGalleryTooltip = useMemo(
      () => (allowViewGallery ? '' : 'Record has no images to view.'),
      [allowViewGallery]
   )

   const handleViewGalleryClick = () => setOpenImageViewer(!openImageViewer)

   // Image match functionality
   const [imageMatchModalOpen, setImageMatchModalOpen] =
      useState<boolean>(false)
   const [matchedImages, setMatchedImages] = useState<CatalogueImage[]>([])

   const {
      imageMatch,
      imageMatchData,
      imageMatchError,
      isImageMatchLoading,
      isImageMatchError,
      isImageMatchSuccess,
   } = useImageMatch()

   useEffect(() => {
      if (isImageMatchLoading) {
         setImageMatchModalOpen(true)
      }
   }, [isImageMatchLoading])

   useEffect(() => {
      if (imageMatchData && imageMatchData?.CatalogueImages?.length > 0) {
         setMatchedImages(imageMatchData.CatalogueImages)
      }
   }, [imageMatchData])

   const allowImageMatch = useMemo(() => localImages?.length < 4, [localImages])

   const imageMatchTooltip = useMemo(
      () =>
         allowImageMatch
            ? ''
            : 'Maximum of 4 images allowed. Delete one to run Image Match.',
      [allowImageMatch]
   )

   const { list: manufacturers } = useManufacturers()

   const manufacturerOptions = useMemo(
      () =>
         manufacturers?.map((man) => ({
            value: String(man.Id),
            label: man.Name,
         })),
      [manufacturers]
   )

   const handleManufacturerChange = (selected: Option) => {
      setSelectedManufacturer(selected)
   }

   const handleModelNumberChange = (e: ChangeEvent<HTMLFormElement>) =>
      setModelNumber(e.target.value)

   const handleImageMatchClick = async () => {
      const payload: ImageMatchPayload = {
         Manufacturer: selectedManufacturer?.label,
         ManufacturerID: Number(selectedManufacturer?.value),
         ModelNumber: modelNumber,
      }
      await imageMatch(payload)
   }

   const handleImageSelection = (selectedImage: CatalogueImage) => {
      const updatedImages = matchedImages.map((image) =>
         image.PictureFullURL === selectedImage.PictureFullURL &&
         image.Position === selectedImage.Position
            ? {
                 ...image,
                 selected: image.selected ? false : true,
              }
            : image
      )
      setMatchedImages(updatedImages)
   }

   const handleAddMatchedImages = () => {
      matchedImages
         .filter((image) => image.selected)
         .map((image, index) => handleAddMatchedImage(image, index))
      setMatchedImages([])
      setImageMatchModalOpen(false)
   }

   const handleAddMatchedImage = (image: CatalogueImage, index: number) => {
      const isDuplicateImage = !!localImages.find(
         (img) => img.PictureFullURL === image.PictureFullURL
      )
      // if its not a duplicate image then add it to images array
      if (!isDuplicateImage) {
         const Position = localImages.length + index
         const newImage = {
            ...image,
            IsMatchedImage: true,
            Position: Position,
         }
         setLocalImages((prevState) => [...prevState, newImage])
      }
   }

   const imageMatchErrorMessage: string | null = useMemo(() => {
      if (isImageMatchError && imageMatchError) {
         return imageMatchError
      }
      if (isImageMatchSuccess && matchedImages.length === 0) {
         return 'No Images were matched to this item.'
      }
      return null
   }, [isImageMatchError, imageMatchError, matchedImages, isImageMatchSuccess])

   // Image Drag functionality
   const reorderImages = (
      list: Iterable<CardImage> | ArrayLike<CardImage>,
      startIndex: number,
      endIndex: number
   ) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      const processed = result.map((item, index) => ({
         ...item,
         Position: index,
      }))
      return processed
   }

   const onDragEnd = (result: {
      destination: { index: number }
      source: { index: number }
   }) => {
      if (!result.destination) {
         return
      }

      const updatedImages = reorderImages(
         localImages,
         result.source.index,
         result.destination.index
      )
      setLocalImages(updatedImages)
   }

   const handleSaveClick = async () => {
      const params: SaveImageRecordParams = {
         catalogueId: imageRecord.CatalogueMasterId,
         localImages: localImages,
         deletionIds: imageIdsToDelete,
         manufacturerId: Number(selectedManufacturer.value),
         manufacturer: selectedManufacturer.label,
         modelNumber: modelNumber,
      }
      await handleSave(params)
   }

   return (
      <Modal
         isModalVisible={isOpen}
         closeModal={handleClose}
         title={title}
         modalInnerStyles={modalStyles.inner}
      >
         <div className={styles.modalContent}>
            <Row gutterWidth={20} style={modalStyles.fieldsContainer}>
               <Col xs={6}>
                  <Dropdown
                     name="Manufacturer"
                     defaultValue={selectedManufacturer}
                     onChange={handleManufacturerChange}
                     options={manufacturerOptions}
                     id="Manufacturer"
                     label="Manufacturer"
                     placeholder="Manufacturer"
                     isClearable={false}
                     required
                  />
               </Col>
               <Col xs={6}>
                  <Input
                     id="ModelNumber"
                     label="Model Number"
                     value={modelNumber}
                     onChange={handleModelNumberChange}
                     error={
                        modelNumber?.length === 0
                           ? 'This field is required'
                           : undefined
                     }
                     inputProps={{
                        value: modelNumber,
                     }}
                     isControlled
                     required
                  />
               </Col>
            </Row>

            <Row gutterWidth={0}>
               <Col xs={9}>
                  <DragDropContext onDragEnd={onDragEnd}>
                     <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, _snapshot) => (
                           <section
                              className={styles.imagesContainer}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                           >
                              {localImages?.length > 0 &&
                                 localImages.map((image, index) => (
                                    <Draggable
                                       key={image.PictureFullURL}
                                       draggableId={image.PictureFullURL}
                                       index={index}
                                    >
                                       {(provided, _snapshot) => (
                                          <div
                                             ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                          >
                                             <ImageCard
                                                isPrimaryImage={index === 0}
                                                src={image.PictureFullURL}
                                                alt={`${imageRecord.Manufacturer} ${imageRecord.ModelNumber} ${image.Position}`}
                                                onDeleteClick={() =>
                                                   onDeleteImageClick(image)
                                                }
                                                isMatchedImage
                                             />
                                          </div>
                                       )}
                                    </Draggable>
                                 ))}
                              {provided.placeholder}
                              {allowImageMatch && (
                                 <UploadImageButton
                                    onImageUpload={onImageUpload}
                                 />
                              )}
                           </section>
                        )}
                     </Droppable>
                  </DragDropContext>
               </Col>

               <Col xs={3} className={styles.actionButtonsContainer}>
                  <Button
                     type="button"
                     variant={!allowViewGallery ? 'tertiary' : 'primary'}
                     preserveText
                     onClick={handleViewGalleryClick}
                     disabled={!allowViewGallery}
                     title={viewGalleryTooltip}
                     icon={
                        <Svg
                           id="view"
                           fill={!allowViewGallery ? '#373737' : 'white'}
                        />
                     }
                  >
                     View Gallery
                  </Button>
                  <ImageMatchButton
                     isTertiary={!allowImageMatch}
                     disabled={!allowImageMatch}
                     tooltip={imageMatchTooltip}
                     onClick={handleImageMatchClick}
                  />
               </Col>
            </Row>

            {imageMatchModalOpen && (
               <ImageMatchModal
                  isOpen={imageMatchModalOpen}
                  handleClose={() => setImageMatchModalOpen(false)}
                  isSuccess={isImageMatchSuccess}
                  errorMessage={imageMatchErrorMessage}
                  matchedImages={matchedImages}
                  handleImageSelection={handleImageSelection}
                  handleAddMatchedImages={handleAddMatchedImages}
                  maxAllowedImages={4 - localImages.length}
               />
            )}

            {localImages?.length > 0 && (
               <ImageViewer
                  open={openImageViewer}
                  setOpen={setOpenImageViewer}
                  images={localImages.map((img) => ({
                     src: img?.PictureFullURL,
                  }))}
               />
            )}

            <hr />

            <Row gutterWidth={20} style={modalStyles.fieldsContainer}>
               <Col xs={6}>
                  <Button
                     minWidth="100%"
                     type="button"
                     onClick={handleSaveClick}
                     disabled={modelNumber?.length === 0}
                  >
                     Save
                  </Button>
               </Col>
               <Col xs={6}>
                  <Button
                     type="button"
                     minWidth="100%"
                     variant="tertiary"
                     onClick={handleClose}
                  >
                     Cancel
                  </Button>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}

export default EditImageModal
