import { useMutation, useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useAPI } from '..'
import { PATH } from '../../constants/global'

import {
   ImagesListOptions,
   UseImagesData,
   UploadImagePayload,
   UpdateImageRecordPayload,
   UpdateImagePositionsPayload,
   ImagePosition,
} from './types'
import { useEffect, useState } from 'react'
import { UploadMatchedImagesPayload } from '../useImageMatch/types'

export default function (): UseImagesData {
   const request = useAPI()
   const [errorMessage, setErrorMessage] = useState<string | null>(null)
   const [listOptions, setListOptions] = useState<ImagesListOptions | null>(
      null
   )
   const [uploadedImages, setUploadedImages] = useState<ImagePosition[]>([])

   async function getImagesFromAPI(
      options: ImagesListOptions
   ): Promise<AxiosResponse> {
      return await request.post(PATH.IMAGES.LIST, options)
   }
   const { data, isLoading, isError, refetch, isRefetching } = useQuery(
      'images',
      (): Promise<AxiosResponse> => getImagesFromAPI(listOptions),
      {
         enabled: !!listOptions,
         onError: (
            error: AxiosError<{ message?: string; Message?: string }>
         ) => {
            const msg = `Error loading images: ${
               error?.response?.data?.Message ?? error?.response?.data?.message
            }`
            setErrorMessage(msg)
         },
      }
   )

   useEffect(() => {
      if (listOptions) {
         refetch()
      }
   }, [listOptions])

   async function deleteImageRecordFromAPI(
      recordId: number
   ): Promise<AxiosResponse> {
      return await request.post(
         `${PATH.IMAGES.DELETE_RECORD}?MasterCatalogueID=${recordId}`
      )
   }
   const {
      isError: isDeleteRecordError,
      isLoading: isDeletingRecord,
      isSuccess: isDeleteRecordSuccess,
      mutate: mutateDeleteRecord,
   } = useMutation('delete-image-record', deleteImageRecordFromAPI, {
      onError: (error: AxiosError<{ message?: string; Message?: string }>) => {
         const msg = `Error deleting image record: ${
            error?.response?.data?.Message ?? error?.response?.data?.message
         }`
         setErrorMessage(msg)
      },
   })
   const deleteImageRecord = (recordId: number) => mutateDeleteRecord(recordId)

   async function updateImageRecordInAPI(
      payload: UpdateImageRecordPayload
   ): Promise<AxiosResponse> {
      return await request.post(PATH.IMAGES.UPDATE, payload)
   }
   const {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      mutate: mutateUpdate,
   } = useMutation('update-image-record', updateImageRecordInAPI, {
      onError: (error: AxiosError<{ Message?: string; message?: string }>) => {
         const msg = `Error updating image record: ${
            error?.response?.data?.Message ?? error?.response?.data?.message
         }`
         setErrorMessage(msg)
      },
   })
   const updateImageRecord = (payload: UpdateImageRecordPayload) =>
      mutateUpdate(payload)

   async function uploadImageInAPI(
      payload: UploadImagePayload
   ): Promise<AxiosResponse> {
      return await request.post(PATH.IMAGES.UPLOAD, payload, {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      })
   }
   const {
      isLoading: isUploading,
      isSuccess: isUploadSuccess,
      isError: isUploadError,
      mutate: mutateUpload,
   } = useMutation('upload-image', uploadImageInAPI, {
      onError: (error: AxiosError<{ message?: string; Message?: string }>) => {
         const msg = `Error uploading images: ${
            error?.response?.data?.Message ?? error?.response?.data?.message
         }`
         setErrorMessage(msg)
      },
      onSuccess: (data) => {
         const uploadedImages = data?.data?.CatalogueImages?.map((img) => ({
            CatalogueImageId: img.CatalogueImageId,
            Position: img.Position,
         }))
         setUploadedImages((prevImages) => [...prevImages, uploadedImages])
      },
   })
   const uploadImage = (payload: UploadImagePayload) => mutateUpload(payload)

   async function uploadMatchedImagesInAPI(
      payload: UploadMatchedImagesPayload
   ): Promise<AxiosResponse> {
      return await request.post(PATH.IMAGES.UPLOAD_MATCHED, payload)
   }
   const {
      isLoading: isUploadingMatched,
      isSuccess: isUploadMatchedSuccess,
      isError: isUploadMatchedError,
      mutate: mutateUploadMatched,
   } = useMutation('upload-matched-images', uploadMatchedImagesInAPI, {
      onError: (error: AxiosError<{ message?: string; Message?: string }>) => {
         const msg = `Error uploading matched images: ${
            error?.response?.data?.Message ?? error?.response?.data?.message
         }`
         setErrorMessage(msg)
      },
      onSuccess: (data) => {
         const uploadedImages = data?.data?.selectedImages?.map((img) => ({
            CatalogueImageId: img.CatalogueImageId,
            Position: img.Position,
         }))
         setUploadedImages((prevImages) => [...prevImages, uploadedImages])
      },
   })
   const uploadMatchedImages = (payload: UploadMatchedImagesPayload) =>
      mutateUploadMatched(payload)

   async function deleteImagesFromAPI(
      imageIds: number[]
   ): Promise<AxiosResponse> {
      return await request.post(PATH.IMAGES.DELETE, imageIds)
   }
   const {
      isError: isDeleteError,
      isLoading: isDeleting,
      isSuccess: isDeleteSuccess,
      mutate: mutateDelete,
   } = useMutation('delete-images', deleteImagesFromAPI, {
      onError: (error: AxiosError<{ message?: string; Message?: string }>) => {
         const msg = `Error deleting images: ${
            error?.response?.data?.Message ?? error?.response?.data?.message
         }`
         setErrorMessage(msg)
      },
   })
   const deleteImages = (ids: number[]) => mutateDelete(ids)

   async function updateImagePositionsInAPI(
      payload: UpdateImagePositionsPayload
   ): Promise<AxiosResponse> {
      return await request.post(PATH.IMAGES.UPDATE_IMAGE_POSITIONS, payload)
   }
   const {
      isError: isPositionsError,
      isLoading: isPositionsLoading,
      isSuccess: isPositionsSuccess,
      mutate: mutatePositions,
   } = useMutation('update-image-positions', updateImagePositionsInAPI, {
      onError: (error: AxiosError<{ message?: string; Message?: string }>) => {
         const msg = `Error updating image positions: ${
            error?.response?.data?.Message ?? error?.response?.data?.message
         }`
         setErrorMessage(msg)
      },
   })
   const updateImagePositions = (payload: UpdateImagePositionsPayload) =>
      mutatePositions(payload)

   return {
      images: data?.data ?? [],
      isLoading,
      isRefetching,
      isError,
      errorMessage,
      setListOptions,
      refetch,
      isUploading,
      isUploadSuccess,
      isUploadError,
      uploadImage,
      isUploadingMatched,
      isUploadMatchedSuccess,
      isUploadMatchedError,
      uploadMatchedImages,
      isDeleting,
      isDeleteError,
      isDeleteSuccess,
      deleteImages,
      isDeletingRecord,
      isDeleteRecordError,
      isDeleteRecordSuccess,
      deleteImageRecord,
      isUpdating,
      isUpdateSuccess,
      isUpdateError,
      updateImageRecord,
      isPositionsLoading,
      isPositionsSuccess,
      isPositionsError,
      updateImagePositions,
      uploadedImages,
   }
}
