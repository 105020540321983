import React from 'react'

import Images from '../components/views/images/images'
import { LayoutLoggedIn } from '../components/views/layout'

export default function ImagesPage() {
   return (
      <LayoutLoggedIn>
         <Images />
      </LayoutLoggedIn>
   )
}
