const modalStyles = {
   inner: {
      width: '900px',
   },
   fieldsContainer: {
      padding: '0 14px',
   },
}

export { modalStyles }
