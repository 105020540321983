import React, { useState, useMemo, useEffect, useCallback } from 'react'
import {
   CrudActionButtons,
   Image as TableImage,
   LoadingOverlay,
   PageHeader,
   Search,
} from '../../common'
import { SnackBarState } from '../../common/snackBar/types'
import SnackBar, { closedSnackbarState } from '../../common/snackBar/snackBar'
import { Severity } from '../../../types'
import { Actions, PAGINATION } from '../../../constants/tables'
import { TableWrapper } from '../../common/table/TableWrapper'
import { TableColumnCellWrapper } from '../../common/table/tableColumnCellWrapper'
import { WarningModal } from '../../common/warningModal/warningModal'
import EditImageModal from './editImageModal'
import {
   ImagePosition,
   ImageRecord,
   ImagesListOptions,
   UpdateImagePositionsPayload,
   UpdateImageRecordPayload,
   UploadImagePayload,
} from '../../../hooks/useImages/types'
import useImages from '../../../hooks/useImages/useImages'
import {
   CatalogueImage,
   UploadMatchedImagesPayload,
} from '../../../hooks/useImageMatch/types'
import { SaveImageRecordParams, CardImage } from './types'
import { renderDateOrNothing } from '../../../common/utils/functions'
import { getPictureDomain } from './helpers'

const Images = () => {
   const [snackbar, setSnackbar] = useState<SnackBarState>(closedSnackbarState)
   const [searchCriteria, setSearchCriteria] = useState<string>('')
   const [isSearchMode, setIsSearchMode] = useState<boolean>(false)
   const [pageSized, setPageSized] = useState<number>(PAGINATION.pageSized)
   const [isPageReset, setIsPageReset] = useState<boolean>(false)
   const [isPaginationReset, setIsPaginationReset] = useState<boolean>(false)
   const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
   const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
   const [selectedImageRecord, setSelectedImageRecord] =
      useState<ImageRecord | null>(null)

   const {
      images,
      isError,
      isLoading,
      isRefetching,
      setListOptions,
      refetch,
      isDeleteRecordError,
      isDeletingRecord,
      isDeleteRecordSuccess,
      deleteImageRecord,
      errorMessage,
      isUploading,
      isUploadError,
      isUploadSuccess,
      uploadImage,
      isUploadingMatched,
      isUploadMatchedError,
      isUploadMatchedSuccess,
      uploadMatchedImages,
      isDeleting,
      isDeleteError,
      isDeleteSuccess,
      deleteImages,
      isUpdating,
      isUpdateError,
      isUpdateSuccess,
      updateImageRecord,
      isPositionsLoading,
      isPositionsError,
      isPositionsSuccess,
      updateImagePositions,
   } = useImages()

   useEffect(() => {
      if (
         isUpdateSuccess ||
         isUploadSuccess ||
         isUploadMatchedSuccess ||
         isDeleteSuccess ||
         isPositionsSuccess
      ) {
         refetch()
      }
   }, [
      isUpdateSuccess,
      isUploadSuccess,
      isUploadMatchedSuccess,
      isDeleteSuccess,
      isPositionsSuccess,
   ])

   useEffect(() => {
      if (isError) {
         setSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
         })
      }
   }, [isError])

   useEffect(() => {
      if (isDeleteRecordSuccess) {
         setSnackbar({
            message: 'The image record was deleted.',
            severity: Severity.SUCCESS,
         })
         setDeleteModalOpen(false)
         refetch()
      }
   }, [isDeleteRecordSuccess])

   useEffect(() => {
      if (isDeleteRecordError) {
         setSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
         })
         setDeleteModalOpen(false)
      }
   }, [isDeleteRecordError])

   useEffect(() => {
      if (isUpdateError) {
         setSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
         })
      }
   }, [isUpdateError])

   useEffect(() => {
      if (isUploadError) {
         setSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
         })
      }
   }, [isUploadError])

   useEffect(() => {
      if (isUploadMatchedError) {
         setSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
         })
      }
   }, [isUploadMatchedError])

   useEffect(() => {
      if (isDeleteError) {
         setSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
         })
      }
   }, [isDeleteError])

   useEffect(() => {
      if (isPositionsError) {
         setSnackbar({
            message: errorMessage,
            severity: Severity.ERROR,
         })
      }
   }, [isPositionsError])

   const handleGetImageRecords = (
      query?: string,
      skip?: number,
      take?: number
   ) => {
      const requestPayload: ImagesListOptions = {
         query,
         skip,
         take,
      }
      setListOptions(requestPayload)
   }

   const handleSearchQuery = (query?: string, skip?: number, take?: number) => {
      setIsPaginationReset(true)
      if (query) {
         setSearchCriteria(query)
      }
      handleGetImageRecords(query, skip, take)
   }

   const handleDeleteImageRecord = async () => {
      await deleteImageRecord(selectedImageRecord?.CatalogueMasterId)
      setDeleteModalOpen(false)
   }

   const showActionForm = (action: Actions, id: string) => {
      const selected = images.Catalogues?.find(
         (record) => record.CatalogueMasterId === Number(id)
      )
      setSelectedImageRecord(selected)
      switch (action) {
         case Actions.Delete:
            setDeleteModalOpen(true)
            break
         case Actions.Edit:
            setEditModalOpen(true)
            break
         default:
            break
      }
   }

   const handleUploadAddedImages = (
      masterCatalogueID: number,
      uploadedImages: CardImage[]
   ) =>
      uploadedImages
         .map((img) => ({
            masterCatalogueID: masterCatalogueID,
            'file[0]': img.File0,
            'file[1]': img.File1,
            Position: img.Position,
         }))
         .forEach((img: UploadImagePayload) => uploadImage(img))

   const handleUploadMatchedImages = (
      manufacturerId: number,
      manufacturer: string,
      modelNumber: string,
      selectedImages: CatalogueImage[]
   ) => {
      const uploadImagesPayload: UploadMatchedImagesPayload = {
         ManufacturerID: manufacturerId,
         Manufacturer: manufacturer,
         ModelNumber: modelNumber,
         selectedImages: selectedImages,
      }
      uploadMatchedImages(uploadImagesPayload)
   }

   const handleDeleteImages = (imageIds: number[]) => deleteImages(imageIds)

   const handleUpdateImageRecord = (
      id: number,
      manufacturerId: number,
      modelNumber: string
   ) => {
      const updatePayload: UpdateImageRecordPayload = {
         CatalogueMasterId: id,
         ManufacturerID: manufacturerId,
         ModelNumber: modelNumber,
      }
      updateImageRecord(updatePayload)
   }

   const handleUpdatePositions = (
      catalogueId: number,
      catalogueImages: ImagePosition[]
   ) => {
      const updatedPositions: UpdateImagePositionsPayload = {
         catalogueMasterId: catalogueId,
         catalogueImages: catalogueImages,
      }
      updateImagePositions(updatedPositions)
   }

   const handleSaveEditModal = (params: SaveImageRecordParams) => {
      const {
         deletionIds,
         localImages,
         catalogueId,
         manufacturerId,
         manufacturer,
         modelNumber,
      } = params
      // 1. update image record
      handleUpdateImageRecord(catalogueId, manufacturerId, modelNumber)
      // 2. delete images if any were added to deletion array
      if (deletionIds?.length > 0) {
         handleDeleteImages(deletionIds)
      }
      // 3. upload local images if any were added
      const addedImages = localImages?.filter((img) => img.File0 && img.File1)
      if (addedImages?.length > 0) {
         handleUploadAddedImages(catalogueId, addedImages)
      }
      // 4. upload matched images if any were added
      const matchedImagesAdded = localImages?.filter(
         (img) => img.IsMatchedImage
      )
      if (matchedImagesAdded?.length > 0) {
         handleUploadMatchedImages(
            manufacturerId,
            manufacturer,
            modelNumber,
            matchedImagesAdded
         )
      }
      // 5. update the image positions in case they were changed
      if (localImages?.length > 0) {
         const imagePositions: ImagePosition[] = localImages?.map((img) => ({
            CatalogueImageId: img.CatalogueImageId,
            Position: img.Position,
         }))
         handleUpdatePositions(catalogueId, imagePositions)
      }

      handleEditModalClose()
      setSnackbar({
         message: 'The image record was updated.',
         severity: Severity.SUCCESS,
      })
   }

   const handleEditModalClose = () => {
      setEditModalOpen(false)
      setSelectedImageRecord(null)
   }

   const renderImageLink = useCallback(
      (url?: string) => {
         return url ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
               {getPictureDomain(url)}
            </a>
         ) : (
            '-'
         )
      },
      [getPictureDomain]
   )

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'CatalogueMasterId',
            id: 'CTA',
            maxWidth: 100,
            align: 'center',
            Cell: (cell) => (
               <CrudActionButtons
                  cell={cell}
                  action={showActionForm}
                  editAction
                  deleteAction
               />
            ),
         },
         {
            Header: 'Primary',
            accessor: 'CatalogueImages',
            id: 'Image1',
            allowBlank: true,
            align: 'center',
            maxWidth: 100,
            height: 35,
            Cell: (cell: {
               row: {
                  original: {
                     CatalogueImages: CatalogueImage[]
                     Manufacturer: string
                     ModelNumber: number
                  }
               }
            }) => {
               const { Manufacturer, ModelNumber, CatalogueImages } =
                  cell.row.original
               const firstImage = CatalogueImages[0]
               return (
                  <TableImage
                     url={firstImage ? firstImage?.PictureFullURL : undefined}
                     alt={`${Manufacturer} ${ModelNumber.toString()}`}
                     defaultImagePadding={3}
                     width={25}
                  />
               )
            },
         },
         {
            Header: 'Manufacturer',
            accessor: 'Manufacturer',
            id: 'Manufacturer',
            minWidth: 300,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.Manufacturer ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Model Number',
            accessor: 'ModelNumber',
            id: 'ModelNumber',
            minWidth: 100,
            Cell: ({ row }) => (
               <TableColumnCellWrapper
                  content={row.original.ModelNumber ?? '-'}
                  disabled={false}
               />
            ),
         },
         {
            Header: 'Created Date',
            accessor: 'DateCreated',
            id: 'DateCreated',
            align: 'center',
            Cell: renderDateOrNothing,
         },
         {
            Header: 'Image 1',
            accessor: 'CatalogueImages',
            id: 'Image1URL',
            minWidth: 300,
            Cell: (cell: {
               row: {
                  original: {
                     CatalogueImages: CatalogueImage[]
                  }
               }
            }) => {
               const firstImage = cell.row.original.CatalogueImages[0]
               return (
                  <TableColumnCellWrapper
                     content={renderImageLink(firstImage?.PictureFullURL)}
                     disabled={false}
                  />
               )
            },
         },
         {
            Header: 'Image 2',
            accessor: 'CatalogueImages',
            id: 'Image2URL',
            minWidth: 300,
            Cell: (cell: {
               row: {
                  original: {
                     CatalogueImages: CatalogueImage[]
                  }
               }
            }) => {
               const secondImage = cell.row.original.CatalogueImages[1]
               return (
                  <TableColumnCellWrapper
                     content={renderImageLink(secondImage?.PictureFullURL)}
                     disabled={false}
                  />
               )
            },
         },
         {
            Header: 'Image 3',
            accessor: 'CatalogueImages',
            id: 'Image3URL',
            minWidth: 300,
            Cell: (cell: {
               row: {
                  original: {
                     CatalogueImages: CatalogueImage[]
                  }
               }
            }) => {
               const thirdImage = cell.row.original.CatalogueImages[2]
               return (
                  <TableColumnCellWrapper
                     content={renderImageLink(thirdImage?.PictureFullURL)}
                     disabled={false}
                  />
               )
            },
         },
         {
            Header: 'Image 4',
            accessor: 'CatalogueImages',
            id: 'Image4URL',
            minWidth: 300,
            Cell: (cell: {
               row: {
                  original: {
                     CatalogueImages: CatalogueImage[]
                  }
               }
            }) => {
               const fourthImage = cell.row.original.CatalogueImages[3]
               return (
                  <TableColumnCellWrapper
                     content={renderImageLink(fourthImage?.PictureFullURL)}
                     disabled={false}
                  />
               )
            },
         },
      ],
      [images?.Catalogues]
   )

   const loading = useMemo(
      () =>
         isLoading ||
         isRefetching ||
         isDeletingRecord ||
         isDeleting ||
         isUpdating ||
         isUploading ||
         isUploadingMatched ||
         isPositionsLoading,
      [
         isLoading,
         isRefetching,
         isDeletingRecord,
         isDeleting,
         isUpdating,
         isUploading,
         isUploadingMatched,
         isPositionsLoading,
      ]
   )

   return (
      <>
         <PageHeader title="Images" />
         {loading && <LoadingOverlay />}
         <SnackBar
            open={
               snackbar.message !== '' && snackbar.severity !== Severity.INFO
            }
            message={snackbar.message}
            severity={snackbar.severity}
            resetMessageStateHandler={() => setSnackbar(closedSnackbarState)}
         />
         <Search
            handleQuery={handleSearchQuery}
            searchMode={isSearchMode}
            setSearchMode={setIsSearchMode}
            pageSized={pageSized}
            placeHolder={' Images'}
            setSearchCriteria={setSearchCriteria}
            isPageSearchReset={isPageReset}
            setIsPageSearchReset={setIsPageReset}
         />
         {!isLoading && images?.Catalogues && (
            <TableWrapper
               columns={columns}
               isLoading={isLoading}
               data={images.Catalogues as []}
               getItems={handleGetImageRecords}
               skipItems={PAGINATION.skip}
               takeItems={PAGINATION.take}
               totalCount={images?.Count ?? 0}
               extraClassName=""
               isPageReset={isPaginationReset}
               setIsPageReset={setIsPaginationReset}
               searchCriteria={searchCriteria}
               searchMode
               setItemId={(_id) => null}
               setPageSized={setPageSized}
               setSearchMode={() => null}
               hideResultsTitle
            />
         )}
         {deleteModalOpen && (
            <WarningModal
               isModalOpen={deleteModalOpen}
               handleClose={() => setDeleteModalOpen(false)}
               title="Delete Image Record"
               message="Are you sure you want to delete this image record? All images assigned to this record will be removed from the system."
               primaryButtonTitle="Delete"
               primaryButtonMethod={handleDeleteImageRecord}
               primaryButtonVariant="danger"
               secondaryButtonTitle="Cancel"
               secondaryButtonMethod={() => setDeleteModalOpen(false)}
               secondaryButtonVariant="tertiary"
            />
         )}
         {selectedImageRecord && (
            <EditImageModal
               isOpen={editModalOpen}
               handleClose={handleEditModalClose}
               imageRecord={selectedImageRecord}
               handleSave={handleSaveEditModal}
            />
         )}
      </>
   )
}

export default Images
