const convertStringToNumber = (value: string | null) =>
   value === '' || value === null ? 0 : Number(value)

const getPictureDomain = (url: string): string => {
   const parts = url.split('//')
   const domainAndPath = parts[1]
   const domainParts = domainAndPath.split('/')
   return parts[0] + '//' + domainParts[0]
}

export { convertStringToNumber, getPictureDomain }
